import React from "react";
import { useState } from "react";

import { Modal } from "antd";

import { assignEmailToUser, assignInvoiceToUser, searchUsers } from "../store/userSlice";
import DebounceSelect from "./DebounceSelect";
import styled from "styled-components";
import { breakpoint } from "../theme";

interface AssignToModalProps {
  invoices: React.Key[];
  emails: React.Key[];
  isModalOpen: boolean;
  closePopup: (refresh: boolean) => void;
}

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

function AssignToModal(props: AssignToModalProps) {
  const [value, setValue] = useState<any>();

  const fetchUserList = async (criteria: string): Promise<any[]> => {
    return searchUsers(criteria);
  };

  const onCancel = (refresh: boolean = false) => {
    setValue(undefined);
    props.closePopup(refresh);
  };

  const handleAssignTo = () => {
    if (props.emails.length)
      assignEmailToUser(props.emails, value.key, value.label).then((res) => {
        onCancel(true);
      });
    else if (props.invoices.length)
      assignInvoiceToUser(props.invoices, value.key, value.label).then((res) => {
        onCancel(true);
      });
  };

  return (
    (<Modal title="Select Assign to" open={props.isModalOpen} width={"600px"} onCancel={() => onCancel(false)} onOk={handleAssignTo}>
      <Row>
        <DebounceSelect
          value={value}
          placeholder="Select user"
          fetchOptions={fetchUserList}
          onChange={(newValue) => {
            setValue(newValue as any);
          }}
          style={{ width: "100%" }}
        />
      </Row>
    </Modal>)
  );
}

export default AssignToModal;
