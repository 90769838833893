import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import invoices from "./invoicesSlice";
import emails from "./emailsSlice";
import messages from "./messagesSlice";
import accounts from "./accountsSlice";
import user from "./userSlice";
import signalRHub from "./signalRSlice";
import auditLog from "./auditLogSlice";

const reducer = combineReducers({ accounts, user, invoices, messages, emails, signalRHub, auditLog });

export type RootState = ReturnType<typeof reducer>;

export type AppDispatch = typeof store.dispatch;
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
