import React, { useEffect } from "react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { Button, Spin } from "antd";

import { FeatureFlagProvider } from "./hooks/featureFlagsProvider";
import useInterceptors from "./hooks/useInterceptors";
import useConfigLoader from "./hooks/useConfigLoader";
import useAccessToken from "./hooks/useAccessToken";
import Messages from "./components/Messages";
import Dashboard from "./pages/Dashboard";
import { useAppDispatch } from "./store";
import Header from "./components/Header";
import NotFound from "./pages/NotFound";
import styled from "styled-components";
import Invoice from "./pages/Invoice";
import "./styles/styles.scss";

const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Loading = () => (
  <CenteredDiv>
    <Spin size="large" />
  </CenteredDiv>
);

let App = ({ envConfig }: { envConfig: any }) => {
  const dispatch = useAppDispatch();
  const token = useAccessToken(envConfig);
  const isReady = useInterceptors({ envConfig, token });

  useEffect(() => {
    document.title = envConfig?.appConfig?.title ?? "Invoice Review";
    document.documentElement.className = "";
    if (envConfig?.appConfig?.theme) {
      document.documentElement.classList.add(`theme-${envConfig.appConfig.theme}`);
    }

    if (!isReady) return;
  }, [dispatch, isReady, envConfig]); // Update if authState changes
  
  if (!isReady) {
    return <Loading />;
  }
  return (
    <FeatureFlagProvider>
      <Header />
      <Messages />

      <Switch>
        <Route exact path="/">
          <Dashboard envConfig={envConfig} />
        </Route>
        <Route path="/invoice/:id" children={<Invoice envConfig={envConfig} />}></Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </FeatureFlagProvider>
  );
};
App = React.memo(App);
const AppWithSecurity = () => {
  const { isConfigLoaded, envConfig, authConfig } = useConfigLoader();
  const { instance } = useMsal();

  return (
    isConfigLoaded && (
      <MsalProvider instance={authConfig}>
        <AuthenticatedTemplate>
          <App envConfig={envConfig} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <span>An error occured during authentication, please try again</span>
          <p>
            <Button onClick={() => instance.loginRedirect()}>Login</Button>
          </p>
        </UnauthenticatedTemplate>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}></MsalAuthenticationTemplate>
      </MsalProvider>
    )
  );
};

const AppWithRouter = () => (
  <Router>
    <AppWithSecurity />
  </Router>
);

export default AppWithRouter;
