import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, List, Modal, Radio, RadioChangeEvent, Space, Spin } from "antd";
import { Upload } from "antd";

import { Attachment, fetchInvoice, fetchSalesForceFields, setInvoiceData, updateInvoice, uploadFiles } from "../store/invoicesSlice";
import {
  CheckCircleFilled,
  FilePdfOutlined,
  InboxOutlined,
  ExclamationCircleFilled,
  CopyOutlined,
  HourglassOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import UserPresenceIcon from "../components/UserPresenceIcon";
import { DetailPageSection, FeatureFlags } from "../hooks/featureFlagsProvider";
import { useAppDispatch, useAppSelector } from "../store";
import InvoiceFields from "../components/InvoiceFields";
import HtmlRenderer from "../components/HtmlRenderer";
import { fetchEmail, fetchInvoices } from "../store/emailsSlice";
import BlankCard from "../components/BlankCard";
import EmailInfo from "../components/EmailInfo";
import Renderer from "../components/Renderer";
import useSignalR from "../hooks/useSignalr";
import { explodeFileName } from "../utils";
import styled from "styled-components";
import { breakpoint } from "../theme";
import { IPresenceUser } from "../types";
import AuditLog from "../components/AuditLog";
import { fetchAuditLogByAttachmentId } from "../store/auditLogSlice";

const { Dragger } = Upload;
const { confirm } = Modal;

interface Props {
  envConfig: any;
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  overflow: hidden;
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

function Invoice({ envConfig }: Props) {
  const dispatch = useAppDispatch();
  const { features } = useContext(FeatureFlags);
  const { id } = useParams<{ id: string }>();
  const search = useLocation().search;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const invoices = useAppSelector((state) => state.emails.invoices);
  const loadingInvoices = useAppSelector((state) => state.invoices.loading);
  const currentInvoice = useAppSelector((state) => state.invoices.current);
  const [isInvoice, setIsInvoice] = useState(false);

  const loadingEmail = useAppSelector((state) => state.emails.loading);
  const currentEmail = useAppSelector((state) => state.emails.current);
  const roles = useAppSelector((state) => state.user?.roles);

  const [uploading, setUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState<Attachment | null>(null);
  const controlSize = features?.pageLayout.controlsSize ?? "large";

  const presenceUsers: IPresenceUser[] = useAppSelector((state) => state.signalRHub.presenceUsers);

  useEffect(() => {
    const isInvoice = searchParams.get("isInvoice");
    if (isInvoice && isInvoice === "true") {
      setIsInvoice(true);
      dispatch(fetchInvoice(id));
    } else {
      dispatch(fetchEmail(id));
      dispatch(fetchInvoices(id));
    }
    dispatch(fetchSalesForceFields());
  }, [features, dispatch, id, searchParams]);

  useEffect(() => {
    if (isInvoice && currentInvoice && currentInvoice.filename && currentInvoice.emailId) {
      setFile(currentInvoice);
      dispatch(fetchEmail(currentInvoice.emailId));
      dispatch(fetchAuditLogByAttachmentId({ id: currentInvoice.id }));
    }
  }, [currentInvoice, isInvoice, dispatch]);

  const invoice = React.useMemo(() => {
    const { state } = window?.history?.state || window?.history;

    if (state?.selectedId) {
      const inv = invoices.find((i) => i.id === state.selectedId);
      if (inv != null) {
        return inv;
      }
    }

    if (file) {
      const inv = invoices?.find((e) => e.id === file.id);
      if (inv != null) {
        return inv;
      }
    }

    return invoices?.length > 0 ? invoices[0] : null;
  }, [invoices, file]);

  useEffect(() => {
    if (!isInvoice && invoice && invoice.filename && invoice.emailId) {
      setFile(invoice);
    }
  }, [invoice, isInvoice]);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    setUploading(true);

    dispatch(uploadFiles({ id: isInvoice ? id : invoice.id, formData })).finally(() => {
      setFileList([]);
      setUploading(false);
      if (isInvoice) {
        setIsInvoice(true);
        dispatch(fetchInvoice(id));
      } else {
        dispatch(fetchEmail(id));
        dispatch(fetchInvoices(id));
      }
    });
  };

  const disableState = features?.disableStateStatus.some((status) => invoice?.finalizedData?.status === status);
  const hasFullAccessRole = features?.fullAccessRolesNames.some((role) => roles.includes(role));
  const hasReadOnlyAccess = () => (roles.length > 0 ? !hasFullAccessRole : true);
  const readOnly = features?.isFullAccessRoles ? hasReadOnlyAccess() : false;

  const propsUpload = {
    name: "file",
    multiple: true,
    disabled: disableState,
    onDrop(e) {
      console.info("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const getConfirmContent = (message) => {
    const messageParts = message.split(": ");
    const urls = messageParts[1].split(",");
    return (
      <div>
        <p>{messageParts[0]}</p>
        <ul>
          {urls.map((url, index) => (
            <li key={index}>
              <a
                key={index}
                target="_blank"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
                href={`${window.location.origin}/invoice/${url}`}
                rel="noreferrer"
              >
                {url}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  function showConfirm(data, message) {
    confirm({
      title: "Are you sure you want to submit?",
      okText: "Yes",
      icon: <ExclamationCircleFilled />,
      content: getConfirmContent(message),
      onOk() {
        dispatch(updateInvoice({ id: invoice.id, finalizedData: data, ignore: true }));
      },
      onCancel() {
        dispatch(setInvoiceData(data));
      },
    });
  }

  function renderInfo() {
    if (!currentEmail) return null;
    return <EmailInfo email={currentEmail} invoice={isInvoice ? currentInvoice : invoice} isInvoice={isInvoice} />;
  }

  const showIcon = (status: string) => {
    //CheckCircleFilled, FilePdfOutlined, InboxOutlined, ExclamationCircleFilled, CopyOutlined, HourglassOutlined, SearchOutlined, CloseCircleOutlined
    if (features.useStatusIcons) {
      switch (status) {
        case "Processing":
          return (
            <HourglassOutlined
              style={{
                color: "#00FF00",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "New":
          return (
            <HourglassOutlined
              style={{
                color: "#00FF00",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "OCR Completed":
          return (
            <HourglassOutlined
              style={{
                color: "#00FF00",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "Needs Review":
          return (
            <SearchOutlined
              style={{
                color: "#ECAA0D",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "Issues":
          return (
            <ExclamationCircleFilled
              style={{
                color: "#ECAA0D",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "Rejected":
          return (
            <CloseCircleOutlined
              style={{
                color: "#FF0000",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "Salesforce Upload Complete":
          return (
            <CheckCircleFilled
              style={{
                color: "#00aa00",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
        case "Salesforce Upload Failure":
          return (
            <ExclamationCircleFilled
              style={{
                color: "#AA0000",
                fontSize: "16px",
                margin: "0 .5em",
              }}
            />
          );
      }
    } else {
      if (status === "Needs Review") return ``;
      if (features?.showCheckedStatus.includes(status))
        return (
          <CheckCircleFilled
            style={{
              color: "#00AA00",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      else if (features?.showFailedStatus.includes(status))
        return (
          <ExclamationCircleFilled
            style={{
              color: "#FF0000",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
    }
  };

  const renderDuplicates = () => {
    const showPotentialDuplicates = features?.showPotentialDuplicates || false;
    if (showPotentialDuplicates) {
      const inv: Attachment = isInvoice ? currentInvoice : invoices?.find((i) => i.filename === file?.filename);
      return (
        <List
          size="small"
          header={<h4>Potential Duplicates</h4>}
          dataSource={inv?.finalizedData?.potentialDuplicateInvoiceIds}
          style={!isInvoice ? { marginLeft: "42px" } : {}}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                window.open(
                  `/invoice/${features.IsDetailsMultiFile ? item.split("[!]")[1] : item.split("[!]")[0]}?isInvoice=${!features.IsDetailsMultiFile}`,
                  "_blank"
                );
              }}
            >
              {explodeFileName(item).filename}
            </List.Item>
          )}
        ></List>
      );
    } else {
      return <></>;
    }
  };

  const renderRelatedDocuments = () => {
    const inv: Attachment = isInvoice ? currentInvoice : invoices?.find((i) => i.filename === file?.filename);
    if (inv?.finalizedData?.relatedAttachments) {
      return (
        <List
          size="small"
          header={<h4>Related Documents</h4>}
          dataSource={inv?.finalizedData?.relatedAttachments}
          style={!isInvoice ? { marginLeft: "42px" } : {}}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                window.open(`/invoice/${item.emailId}?isInvoice=${!features.IsDetailsMultiFile}`, "_blank");
              }}
            >
              {`${item?.finalizedData?.customerName} - ${item?.finalizedData?.plainFilename}`}
            </List.Item>
          )}
        ></List>
      );
    } else {
      <></>;
    }
  };

  /* Detail Page Sections Block */

  const attachmentMetadataSection = (
    <>
      {renderInfo()}
      <UserPresenceIcon id={id} presenceUsers={presenceUsers} />
    </>
  );

  const emailBodySection = <HtmlRenderer html={currentEmail?.body} inline={currentEmail.inline} envConfig={envConfig} />;

  const onChange = (e: RadioChangeEvent) => {
    const id = e.target.value;
    const invoice = invoices.find((i) => i.id === id);
    if (invoice) {
      if (window.history.pushState) {
        window.history.pushState({ selectedId: id }, "", "");
      }
      setFile(invoice);
    }
  };

  const potentialDuplicatesSection = (
    <>
      {!isInvoice && (
        <Radio.Group value={file?.id} onChange={onChange}>
          <Space direction="vertical">
            {[...invoices].map((file) => (
              <Radio key={file?.id} value={file?.id}>
                {showIcon(file.finalizedData.status)}
                {file.finalizedData.originalAttachmentId && file.finalizedData.originalAttachmentId.length > 0 && <CopyOutlined />}
                {` ${file.finalizedData.plainFilename} ${file.finalizedData.customerName ? "- " + file.finalizedData.customerName : ""} ${
                  file.finalizedData.originalAttachmentId ? "- (Duplilcate-" + file.finalizedData.duplicateNumber + ")" : ""
                }`}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
      {renderDuplicates()}
      {renderRelatedDocuments()}
    </>
  );

  const inputFieldsSection = (
    <Spin spinning={loadingInvoices || loadingEmail}>
      <InvoiceFields
        emailId={isInvoice ? currentInvoice.emailId : id}
        invoice={file}
        disabled={disableState || readOnly}
        onDuplicateError={(data, message) => showConfirm(data, message)}
        isInvoice={isInvoice}
      />
    </Spin>
  );

  const additionalDocumentsSection = (
    <div>
      {!isInvoice && <h2>Additional Documents</h2>}
      {isInvoice && <span className="fw-800">Additional Documents</span>}
      {(invoice?.finalizedData || currentInvoice?.finalizedData) && (
        <ul>
          {(invoice?.finalizedData?.documents || currentInvoice?.finalizedData?.documents)?.map((doc, index) => {
            return (
              <li key={index}>
                <p>
                  <a target="_blank" href={doc} rel="noreferrer">
                    <FilePdfOutlined /> file_{index + 1}.{doc.split(".").pop()}
                  </a>
                </p>
              </li>
            );
          })}
        </ul>
      )}
      <Dragger {...propsUpload} accept=".doc,.docx,.pdf" disabled={disableState || readOnly}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Only .pdf, .docx and .doc supported</p>
      </Dragger>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || disableState || readOnly}
        loading={uploading}
        size={controlSize}
        style={{ width: 100, alignSelf: "center", margin: 10 }}
      >
        {uploading ? "Uploading" : "Upload"}
      </Button>
    </div>
  );

  const auditLogSection = <>{file && file.id && <AuditLog invoiceId={file.id} />}</>;

  /* Detail Page Sections Block */

  const renderSection = (section: DetailPageSection) => {
    let content = null;
    let title = section.title || "";
    let spinner = false;

    switch (section.key) {
      case "AttachmentMetadata":
        content = attachmentMetadataSection;
        break;
      case "EmailBody":
        content = emailBodySection;
        break;
      case "PotentialDuplicates":
        content = potentialDuplicatesSection;
        break;
      case "InputFields":
        spinner = true;
        content = inputFieldsSection;
        break;
      case "AdditionalDocuments":
        content = additionalDocumentsSection;
        break;
      case "AuditLog":
        spinner = true;
        content = auditLogSection;
        break;
      default:
        return null;
    }

    return (
      <BlankCard loading={spinner ? false : loadingInvoices || loadingEmail} key={section.key} collapsible={section.collapsible} title={title}>
        {content}
      </BlankCard>
    );
  };

  useSignalR({ id: features.IsDetailsMultiFile ? invoice?.id : id, envConfig, features: features });

  return (
    <Row>
      <div style={{ height: "calc(100vh - 120px)", flex: 1 }}>
        <Renderer src={file?.filename} key={file?.id} envConfig={envConfig} />
      </div>
      <div style={{ height: "calc(100vh - 120px)", overflowX: "hidden", flex: 1 }}>
        {features?.detailPageSections.map((section: DetailPageSection) => renderSection(section))}
      </div>
    </Row>
  );
}

export default Invoice;
