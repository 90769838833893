import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setRoles, setUser } from "../store/userSlice";
import { useAppDispatch } from "../store";
import jwt_decode from "jwt-decode";
import { AccessTokenPayload } from "../types";

function useAccessToken(envConfig) {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!envConfig) return;

      if (accounts.length > 0) {
          const request = {
              scopes: [envConfig.auth.scope],
              account: accounts[0]
          };
          dispatch(setUser(accounts[0].username));
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
              const decoded: AccessTokenPayload = jwt_decode(response.accessToken);
              if (decoded.roles) {
                dispatch(setRoles(decoded.roles));
              }
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request).then(response => {
                  });
              }
          });
      }
    }, [envConfig, accounts, instance, dispatch]);

    return accessToken;
}

export default useAccessToken;