import { useEffect, useState } from "react";
import axios from "axios";
import { useAppDispatch } from "../store";
import { useMsal } from "@azure/msal-react";

function useInterceptors({ envConfig, token }) {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    if (!envConfig || ! token) return;
    setReady(true);

    const requestInterceptor = axios.interceptors.request.use(
      function (config) {
        config.baseURL = envConfig.appConfig.baseApiUrl;
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        };

        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (!error.response.status) return Promise.reject(error);
        // const resData = error.response?.data;
        // if (error.response.status === 400 && resData.includes("Duplicate values were found on the following invoices")) {
        //   return Promise.reject(resData);
        // }
        // dispatch(
        //   pushMessage({
        //     message: "Error " + error?.response?.status,
        //     description: error.message || "something went wrong",
        //     type: "error",
        //   })
        // );
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // if (error.response.status === 401) {
        //   const request = {
        //     scopes: [envConfig.auth.scope],
        //   };
        //   instance.acquireTokenRedirect(request).then();
        // }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [dispatch, envConfig, token, instance]);
  return ready;
}

export default useInterceptors;
