import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pagination, Table, Tooltip } from "antd";
import { MdRequestQuote } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isArray } from "lodash";
import { ColumnModel, DocTypeRename, FeatureFlags, StatusRename } from "../hooks/featureFlagsProvider";
import { explodeFileName, formatDate } from "../utils";
import { RootState, useAppSelector } from "../store";
import UserPresenceIcon from "./UserPresenceIcon";
import { Email, IPresenceUser } from "../types";
import { Filter } from "../pages/Dashboard";

interface Props {
  filter: Filter | null;
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
  selectedEmails: React.Key[];
  onSelectionChange: (selectedRows: React.Key[]) => void;
}

function EmailsTable({ filter, onFilterChange, selectedEmails, onSelectionChange }: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const presenceUsers: IPresenceUser[] = useAppSelector((state) => state.signalRHub.presenceUsers);
  const urlParams = new URLSearchParams(window.location.search);
  const [columns, setColumns] = useState([]);
  const { data, loading, totalCount } = useSelector((state: RootState) => state.emails);
  const { features } = useContext(FeatureFlags);
  const statusRename: StatusRename[] = features.statusRename;
  const docTypeRename: DocTypeRename[] = features.docTypeRename;
  const history = useHistory();

  const handleRecord = useCallback(
    (c: ColumnModel, entity: Email) => {
      let data = "";
      if (entity && entity[c.dbPropertyName]) {
        data = entity[c.dbPropertyName];
      }
      
      switch (c.renderType) {
        case "icons":
          switch(c.dbPropertyName) {
            case "isQuote":
              return entity.isQuote ? <Tooltip title="Contains Potential Quote Document">{<MdRequestQuote style={{fontSize: "large"}} />}</Tooltip> : null;
            default:
              return <UserPresenceIcon id={entity.id} presenceUsers={presenceUsers}></UserPresenceIcon>
          }
        case "filename":
          
          const filename = explodeFileName(data);
          if (filename) {
            return filename.filename;
          }
          return data;
        case "status":
          if (statusRename && isArray(statusRename)) {
            const status = statusRename.find((s) => s.status === data);
            if (status) {
              return status.displayAs ?? data;
            }
          }
          return data;
        case "text":
          if (c.dbPropertyName === "docType") return docTypeRename.find((sr: any) => sr.docType === data)?.displayAs ?? data; 
          return data;
        case "date":
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: formatDate(data, true),
              }}
            />
          );
        case "dateLocal":
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: formatDate(data, true, true),
              }}
            />
          );
      }

      return "";
    },
    [presenceUsers, statusRename, docTypeRename]
  );

  const setHeaderIcon = useCallback(
    (labelIcon: string) => {
      switch(labelIcon) {
        case "isQuote":
          return <MdRequestQuote style={{fontSize: "large"}} />;
        default:
          return "";
      }
    },[]
  );

  useEffect(() => {
    const displayCols = [];
    const columns = features?.displayColumns?.filter((c) => c.displayOrder >= 0);
    if (columns) {
      columns.sort((a, b) => a.displayOrder - b.displayOrder);
      columns.forEach((c: ColumnModel) => {
        let column = {
          title: c.renderType === 'icons' ? setHeaderIcon(c.label) : c.label,
          dataIndex: c.dbPropertyName,
          sorter: c.isSortable,
          render: (_, record) => handleRecord(c, record),
        };

        if (c.showIfStatus === undefined || c.showIfStatus === filter?.status) {
          displayCols.push(column);
        }
      });
    }

    setColumns(displayCols);
  }, [features, filter, handleRecord, setHeaderIcon]);

  const onRowClick = (record: Email, rowIndex?: number) => {
    history.push(`/invoice/${record.id}`);
  };

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.order && sorter.order === "ascend") {
      sessionStorage.setItem(`sortDirection`, sorter.order.slice(0, 3));
      onFilterChange("sortDirection", sorter.order.slice(0, 3));
    }
    if (sorter.order && sorter.order === "descend") {
      sessionStorage.setItem(`sortDirection`, sorter.order.slice(0, 4));
      onFilterChange("sortDirection", sorter.order.slice(0, 4));
    }
    if (sorter.field) {
      sessionStorage.setItem(`sortColumn`, sorter.field.toString());
      onFilterChange("sortColumn", sorter.field);
    }
  };

  const pageIndex = +urlParams.get("page") || 1;
  const pageCount = +urlParams.get("count") || +sessionStorage.getItem(`count`) || 20;
  let beginIndex = (data.length ?? 0) > 0 ? (pageIndex - 1) * pageCount + 1 : 0;
  let endIndex = beginIndex > 0 ? beginIndex + (data.length ?? 0) - 1 : 0;
  if (endIndex > totalCount) endIndex = totalCount;
  
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      onSelectionChange(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  useEffect(() => {
    setSelectedRowKeys(selectedEmails);
  }, [selectedEmails]);

  return (
    <>
      <Table
        className="custom-table"
        onRow={(record, rowIndex) => ({
          onClick: (e) => onRowClick(record, rowIndex),
        })}
        loading={loading}
        rowKey={(e) => e.id}
        columns={columns}
        rowSelection={features.isAssignToEnabled ? {
          type: 'checkbox',
          selectedRowKeys,
          ...rowSelection
        } : null}
        dataSource={data}
        pagination={
          features.pageLayout.useDefaultLayout && {
            onChange: (page, pageSize) => {
              const prevPageSize = sessionStorage.getItem(`count`);
              sessionStorage.setItem(`count`, pageSize.toString());

              onFilterChange("page", page);

              if (prevPageSize !== pageSize.toString()) onFilterChange("count", pageSize);
            },

            total: totalCount,
            pageSize: +urlParams.get("count") || +sessionStorage.getItem(`count`) || 20,
            showSizeChanger: true,
            current: +urlParams.get("page") || 1,
          }
        }
        onChange={handleChange}
      />

      {!features.pageLayout.useDefaultLayout && (
        <>
          <div className="custom-pager pt-20px fs-11px">
            Showing results {beginIndex} to {endIndex} out of {totalCount ?? 0}
          </div>
          <Pagination
            className="custom-pager pt-10px"
            total={totalCount}
            size="small"
            showSizeChanger={false}
            current={pageIndex}
            pageSize={pageCount}
            onChange={(page, pageSize) => {
              const prevPageSize = sessionStorage.getItem(`count`);
              sessionStorage.setItem(`count`, pageSize.toString());

              onFilterChange("page", page);

              if (prevPageSize !== pageSize.toString()) onFilterChange("count", pageSize);
            }}
          />
        </>
      )}
    </>
  );
}

export default EmailsTable;
