import moment from "moment";

export const StatusList: StatusObj[] = [
  {
    label: "New",
    id: 5,
    showInFilter: true,
    key: "new",
    color: "#0984e3",
  },
  {
    label: "Processing",
    id: 4,
    showInFilter: true,
    key: "processing",
    color: "#0984e3",
  },
  {
    label: "Needs Review",
    id: 2,
    showInFilter: true,
    key: "needsReview",
    color: "#0984e3",
  },
  {
    label: "Completed",
    id: 1,
    showInFilter: true,
    key: "completed",
  },
  {
    label: "Salesforce Upload Failure",
    id: 3,
    showInFilter: true,
    key: "error",
    color: "#0984e3",
  },
];

export interface StatusCounts {
  key: string;
  count: number;
  percentage: number;
}

export interface StatusObj {
  id: number;
  label: string;
  showInFilter: boolean;
  key: string;
  displayOrder?: number;
  color?: string;
}

export function ObjectToQueryParam(params: Object): string {
  return (
    Object.keys(params)
      //@ts-ignore
      .filter((key) => params[key])
      //@ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&")
  );
}

export function formatDate(dateString: string, bold: boolean = false, local = false) {
  if (dateString) {

    let offset = moment().utcOffset();
    let date = moment.utc(dateString?.replace(/pm/gi, '')).utcOffset(offset);

    if (local) {
      date = moment(dateString?.replace(/pm/gi, ''));
    }

    if (date.year() === 0) return ``;
    return `${date.format("MM-DD-YYYY h:mm:ss a")} - ${bold ? '<b>' : ''} ${date.fromNow()} ${bold ? '</b>' : ''}`;
  } else {
    return ``;
  }
}

export function formatShortDate(dateString: string) {
  if (dateString) {
    const offset = moment().utcOffset();
    const date = moment.utc(dateString?.replace(/pm/gi, '')).utcOffset(offset);

    if (date.year() === 0) return ``;
    return `${date.format("MM-DD-YYYY")}`;
  } else {
    return ``;
  }
}

export function timeSpent(recordDate: Date | string): string {
  const date = moment.utc(recordDate);
  if (date) {
    return date.fromNow();
  }
  return "";
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), "-", s4(), "-", s4(), "-", s4(), "-", s4(), s4(), s4()].join("").toLowerCase();
}

export const getColorByStatusName = (statusName: string): string => {
  const status = StatusList.find((s) => s.label === statusName);
  if (!status || !status.color) return "#000";

  return status.color;
};

export const getStatusById = (id: number): StatusObj => {
  return StatusList.find((s) => s.id === id);
};

export const getStatusByLabel = (label: string): StatusObj => {
  return StatusList.find((s) => s.label.toLowerCase() === label.toLowerCase());
};

export const explodeFileName = (str: string) => {
  const res = str?.split("[!]");
  const filename = res[res.length-1].split("[:]");
  return {
    emailId: res ? res[0] : "",
    email: res ? res[1] : "",
    received_at: res ? formatDate(res[2]) : "",
    filename: res && filename ? filename[filename.length-1] : "",
  };
};

export const saveJson = (key: string, json: any) => {
  return sessionStorage.setItem(key, JSON.stringify(json));
};

export const getJson = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key)) || [];
};

export const getDaysDiff = (date: string) => {
  return moment(new Date(), "MM-DD-YYYY").diff(moment(date).format("MM-DD-YYYY"), "days");
};

export const wrapWithBrackets = (str: string) => (str ? ` (${str})` : "");

export const parseMomentDate = (dateStr: any) => {
  if (!dateStr) return undefined;
  try {
    const parsed = new Date(dateStr);

    const momentDate = moment(parsed.toISOString().slice(0, 10));

    return momentDate.toString();
  } catch (e) {
    console.error("cannot parse");
  }
  return undefined;
};

export const mapStatusCounts = (payload: any): StatusCounts[] => {
  const counts: StatusCounts[] = [];
  if (payload) {
    const parentKeys = Object.keys(payload);
    parentKeys.forEach(pk => {
      let count: StatusCounts = {
        key: pk,
        count: payload[pk].count,
        percentage: payload[pk].percentage
      }
      counts.push(count);
    });
  }

  return counts;
}
