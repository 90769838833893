import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAddOrRemovePresence } from "../types";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const signalRSlice = createSlice({
  name: "signalRHub",
  initialState: {
    connection: null,
    presenceUsers: [],
  },
  reducers: {
    addOrRemovePresence: (state, action: PayloadAction<IAddOrRemovePresence>) => {
      const { status, user, id } = action.payload;
      const presenceIdx = state.presenceUsers?.findIndex(pu => pu.user === user && pu.entityId === id);
      if (status === "disconnected" && presenceIdx > -1) {
        state.presenceUsers.splice(presenceIdx, 1);
      }

      if (status === "connected" && presenceIdx === -1) {
        state.presenceUsers.push({
          user: user,
          entityId: id,
        });
      }
    },
    openConnection: (state, action: PayloadAction<{ url: string }>) => {
      state.connection = new HubConnectionBuilder()
        .withUrl(action.payload.url)
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect()
        .build();
    },
  },
  extraReducers: {
  }
});

export const { addOrRemovePresence, openConnection } = signalRSlice.actions;
export default signalRSlice.reducer;
