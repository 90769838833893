import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { InputNumber, Button, Form, Modal, Row, Col, Tooltip, Spin, notification, Checkbox } from "antd";
import { fetchInvoice, fetchSalesForceFields, Attachment, splitDocument } from "../store/invoicesSlice";
import { PlusSquareOutlined, DeleteFilled } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchEmail, fetchInvoices } from "../store/emailsSlice";
import { FeatureFlags } from "../hooks/featureFlagsProvider";
import Renderer from "./Renderer";
import useConfigLoader from "../hooks/useConfigLoader";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface SplitModalProps {
  invoice: Attachment;
}

function SplitModal(props: SplitModalProps) {
  const dispatch = useAppDispatch();
  const { features } = useContext(FeatureFlags);
  const roles = useAppSelector((state) => state.user?.roles);
  const { envConfig } = useConfigLoader();

  //callbacks
  const onShowModal = () => {
    setIsModalVisible(true);
  };
  const onAddDocument = () => {
    const start = (splitData[splitData.length - 1]?.end || 0) + 1;
    setSplitData([...splitData, { id: uuidv4(), start: start, end: start }]);
  };
  const onDeleteDocument = (id) => {
    const values = [...splitData];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setSplitData(values);
  };
  const onSubmit = () => {
    let isMounted = true;
    const isValid = validateData();
    const isReProcess = features.isReprocessSplitDocumentEnabled && reProcess;
    if (isValid) {
      if (!isSubmitting) {
        setIsModalVisible(false);
        setIsSubmitting(true);
        dispatch(
          splitDocument({
            id: props.invoice.id,
            payload: {
              reProcess: isReProcess,
              pageRange: splitData.map((data) => `${data.start}-${data.end}`).join(","),
            },
          })
        )
          .then((res) => {
            if (res.type === "invoices/splitInvoice/rejected") {
              notification.open({
                message: "Error",
                description: "An error occurred while splitting document. Please try again.",
              });
            } else {
              if (isMounted) {
                dispatch(fetchInvoice(props.invoice.id));
                dispatch(fetchEmail(props.invoice.emailId));
                dispatch(fetchInvoices(props.invoice.emailId));
                dispatch(fetchSalesForceFields());
                setIsSubmitting(false);
                setSplitData([]);
                notification.open({
                  message: "Document Done",
                  description: "Document successfully split.",
                });
              }
            }
          })
          .catch((err) => {
            console.error(err);
            notification.open({
              message: "Error",
              description: "An error occurred while splitting document. Please try again.",
            });
          })
          .finally(() => {
            setIsSubmitting(false);
            setSplitData([]);
          });
      }
    } else {
      if (isMounted) {
        notification.open({
          message: "Something is wrong with you input",
          description: `First document must start at page one.
          Page ranges may not overlap.
          The last document must end on the last page of the parent document.`,
        });
      }
    }
  };
  const onCancel = () => {
    setSplitData([]);
    setIsModalVisible(false);
  };
  const onInputChange = (id: string, value: number, name: string) => {
    const newValues = splitData.map((data) => {
      if (id === data.id) {
        data[name] = value;
      }
      return data;
    });
    setSplitData(newValues);
  };

  const validateData = (): boolean => {
    const pageLength = props.invoice?.finalizedData.pages.length;
    let i = 0;
    for (let data of splitData) {
      //first split has to start at one
      if (i === 0) {
        if (data?.start !== 1) return false;
      }
      //any split other than first must be last split end + 1
      else if (data.start !== splitData[i - 1]?.end + 1) {
        return false;
      }
      //last split has to end on last page
      if (i === splitData.length - 1) {
        if (data.end !== pageLength) return false;
      }
      i++;
    }
    return true;
  };

  const checkIfCanSplit = useCallback(() => {
    return (
      !["Skipped", "Rejected", " Salesforce Upload Complete", "Split Processed"].includes(props.invoice?.finalizedData?.status) &&
      props.invoice?.finalizedData?.docType === "Multi-page"
    );
  }, [props.invoice]);

  //hooks
  const [canSplit, setCanSplit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [splitData, setSplitData] = useState<Array<{ id: string; start: number; end: number }>>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [reProcess, setReProcess] = useState<boolean>(true);

  const onChange = (e: CheckboxChangeEvent) => {
    setReProcess(e.target.checked);
  };

  useEffect(() => {
    setCanSplit(checkIfCanSplit());
  }, [checkIfCanSplit]);

  useEffect(() => {
    const hasFullAccessRole = features?.fullAccessRolesNames.some((role) => roles.includes(role));
    const hasReadOnlyAccess = () => (roles.length > 0 ? !hasFullAccessRole : true);
    const readOnly = features?.isFullAccessRoles ? hasReadOnlyAccess() : false;
    setReadOnly(readOnly);
  }, [features, roles]);

  //render
  if (canSplit) {
    return (
      <div>
        <Button type="primary" onClick={onShowModal} disabled={readOnly}>
          Split Document
        </Button>
        <Modal title="Split Document" open={isModalVisible} onOk={onSubmit} onCancel={onCancel} width={1000} style={{ top: 40 }}>
          {isSubmitting && <Spin />}
          {!isSubmitting && (
            <div>
              <Row>
                <Col span={16}>
                  <p>First document must start at page one.</p>
                  <p>Page ranges may not overlap.</p>
                  <p>The last document must end on the last page of the parent document.</p>
                  <p>All pages must be used.</p>
                </Col>
              </Row>
              {features.isReprocessSplitDocumentEnabled && (
                <Row style={{ marginBottom: "2em" }}>
                  <Col span={16}>
                    <Checkbox onChange={onChange} checked={reProcess}>
                      Re-Process Split Document(s)
                    </Checkbox>
                  </Col>
                </Row>
              )}
              <Row style={{ marginBottom: "2em" }}>
                <div style={{ height: "500px", flex: 1 }}>
                  <Renderer src={props.invoice.filename} envConfig={envConfig} />
                </div>
              </Row>
              <Row style={{ marginBottom: "2em", textAlign: "end" }}>
                <Col span={8} offset={16}>
                  <Button type="primary" icon={<PlusSquareOutlined />} onClick={onAddDocument}>
                    Add Document
                  </Button>
                </Col>
              </Row>
              <Form name="split-form">
                {splitData.map((data, i) => (
                  <Row key={data.id} gutter={[16, 16]} justify={"space-between"} style={{ marginBottom: "2em" }}>
                    <Col span={6} className="gutter-row">
                      Document {i + 1}
                    </Col>
                    <Col span={8} className="gutter-row">
                      <Form.Item
                        label="Start Page"
                        name={data.id + ":start"}
                        initialValue={data.start}
                        rules={[{ required: true, message: "Missing start page!" }]}
                      >
                        <InputNumber
                          min={1}
                          max={props.invoice?.finalizedData.pages.length || 1000}
                          name="start"
                          value={data.start}
                          onChange={(e) => onInputChange(data.id, e, "start")}
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                      <Form.Item
                        label="End Page"
                        name={data.id + ":end"}
                        initialValue={data.end}
                        rules={[{ required: true, message: "Missing end page!" }]}
                      >
                        <InputNumber
                          min={1}
                          max={props.invoice?.finalizedData.pages.length || 1000}
                          name="end"
                          value={data.end}
                          onChange={(e) => onInputChange(data.id, e, "end")}
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                    <Col span={2} className="gutter-row">
                      <Tooltip title="delete">
                        <Button shape="circle" icon={<DeleteFilled />} onClick={() => onDeleteDocument(data.id)} />
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
              </Form>
            </div>
          )}
        </Modal>
      </div>
    );
  } else return <div></div>;
}

export default SplitModal;
