import React, { ReactNode } from "react";
import { Collapse, Skeleton } from "antd";
const { Panel } = Collapse;

function BlankCard({ style, loading, children, collapsible = false, title = "" }:
  { style?: React.CSSProperties; children: ReactNode; loading?: boolean, collapsible?: boolean, title?: string })
{
  if (collapsible) {
    return(
      <div className="card-wrapper">
        <Skeleton active loading={loading}>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header={title} key="1" className="email-body-header">
              {children}
            </Panel>
          </Collapse>
        </Skeleton>
      </div>
    )
  } else {
    return (
      <div className="card-wrapper">
        <Skeleton active loading={loading}>
          {children}
        </Skeleton>
      </div>
    );
  }
}

export default BlankCard;
