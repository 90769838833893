import React from "react";
import { Col, Row, Tag, Tooltip } from "antd";

import { Filter } from "../pages/Dashboard";
import StatusFilters from "./StatusFilters";
import { StatusCounts } from "../utils";
import TagInput from "./InputWithTags";
import DebounceSelect from "./DebounceSelect";
import { searchServiceTeams } from "../store/serviceTeamSlice";

interface Props {
  tags: string[];
  serviceTeamTags: string[];
  statusCounts: StatusCounts[];
  filter: Filter;
  showServiceTeamDropdownSearch: boolean;
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
  onSearch: (criteria: string[]) => void;
  onSearchServiceTeam: (criteria: string[]) => void;
}

const statusFilterGrid = {
  xs: { span: 11, offset: 5 },
  lg: { span: 15, offset: 1 },
  xl: { span: 13, offset: 3 },
  xxl: { span: 11, offset: 5 },
};

const SearchStyle = {
  margin: 5,
  display: "flex",
  flex: 1,
};

function SearchBar({
  tags,
  serviceTeamTags,
  statusCounts,
  filter,
  onFilterChange,
  onSearch,
  onSearchServiceTeam,
  showServiceTeamDropdownSearch,
}: Props) {
  const fetchServiceTeamList = async (criteria: string): Promise<any[]> => {
    return searchServiceTeams(criteria).then((res: any) => {
      return res;
    });
  };

  const filterByServiceTeam = (value: any) => {
    const newArr = serviceTeamTags;
    if (value && value.value) {
      if (!newArr.includes(value.value)) {
        newArr.push(value.value);
      }
    }

    onSearchServiceTeam(newArr);
  };

  const handleDelete = (tag: string) => {
    const newArr = serviceTeamTags.filter(st => st !== tag);
    onSearchServiceTeam(newArr);
  };

  return (
    <div className="search-bar">
      <Row>
        <Col span={8} className="fw-800 fs-12px">
          Search
        </Col>
        <Col className="fw-800 fs-12px" xs={statusFilterGrid.xs} lg={statusFilterGrid.lg} xl={statusFilterGrid.xl} xxl={statusFilterGrid.xxl}>
          Status
        </Col>
      </Row>
      {showServiceTeamDropdownSearch && (
        <Row className="mt-5px">
          <Col span={4}>
            <TagInput style={SearchStyle} value={tags} onSearch={onSearch} />
          </Col>
          <Col span={4}>
            {serviceTeamTags.map((item) =>
              item.length > 20 ? (
                <Tooltip title={item} key={item}>
                  <Tag closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
                </Tooltip>
              ) : (
                <Tag closable key={item} onClose={() => handleDelete(item)}>
                  {item}
                </Tag>
              )
            )}
            <DebounceSelect
              style={SearchStyle}
              size="middle"
              value={null}
              placeholder="Service Team"
              fetchOptions={fetchServiceTeamList}
              onChange={filterByServiceTeam}
            />
          </Col>
          <Col xs={statusFilterGrid.xs} lg={statusFilterGrid.lg} xl={statusFilterGrid.xl} xxl={statusFilterGrid.xxl}>
            <StatusFilters statusCounts={statusCounts} filter={filter} onFilterChange={onFilterChange} />
          </Col>
        </Row>
      )}
      {!showServiceTeamDropdownSearch && (
        <Row className="mt-5px">
          <Col span={8}>
            <TagInput value={tags} onSearch={onSearch} />
          </Col>
          <Col xs={statusFilterGrid.xs} lg={statusFilterGrid.lg} xl={statusFilterGrid.xl} xxl={statusFilterGrid.xxl}>
            <StatusFilters statusCounts={statusCounts} filter={filter} onFilterChange={onFilterChange} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SearchBar;
