import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { mapStatusCounts, ObjectToQueryParam, StatusCounts } from "../utils";
import { RootState } from ".";

export const fetchAllInvoices = createAsyncThunk("invoices/fetch/all",
  async ({ filter }: { filter?: { search?: string, status?: string, sortColumn?: string, sortDirection?: string }; }, { getState }) => {
    const queryParams = ObjectToQueryParam(filter);

    //@ts-ignore
    const prevQueryParams = getState().invoices.queryParams;

    if (prevQueryParams === queryParams) {
      return Promise.reject();
    }
    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (queryParams) {
        newurl += `?${queryParams}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }

    return axios.get(`/invoices?${queryParams}`).then((res) => ({ response: res.data, queryParams }));
  }
);

export const fetchInvoice = createAsyncThunk("invoice/fetch", async (id: string) => {
  return axios.get(`/invoices/${id}`).then((res) => res.data);
});

export const fetchOptions = async (url: string): Promise<any[]> => {
  return axios.get(url).then((res) => res.data);
};

export const approveInvoice = createAsyncThunk("invoice/approve", async (id: string) => {
  return axios.put(`/invoices/${id}/approve`).then((res) => res.data);
});

export const updateInvoice = createAsyncThunk(
  "invoice/update",
  async ({ id, finalizedData, ignore, approveAllFlag = false }: { id: string; finalizedData: FinalizedData; ignore: boolean, approveAllFlag?: boolean }) => {
    return axios.put(`/invoices/${id}?forceDuplicate=${ignore}&approveAll=${approveAllFlag}`, finalizedData).then((res) => res.data);
  }
);

export const checkPotentialDuplicate = createAsyncThunk(
  "invoice/checkPotentialDuplicate",
  async ({ id, finalizedData }: { id: string; finalizedData: FinalizedData; }) => {
    return axios.put(`/invoices/${id}/potentialDuplicate`, finalizedData).then((res) => res.data);
  }
);

export const cloneInvoice = createAsyncThunk("invoice/clone",
  async ({ id }: { id: string; }) => {
    return axios.post(`/invoices/${id}/clone`, null).then((res) => res.data);
  }
);

export const fetchInvoiceCounts = createAsyncThunk("invoices/counts", async (_, { getState }) => {
  return axios.get(`/invoices/counts`).then((res) => res.data);
});

export const fetchSalesForceFields = createAsyncThunk("salesForceFields/fetch", async (_, { getState }) => {
  //@ts-ignore
  const state: RootState = getState();
  if (state.invoices.salesForceFields?.length) {
    return Promise.reject();
  }
  return axios.get("/ocr-config/field-mappings").then((res) => res.data.SalesforceFields);
});

export const fetchAdditionalSalesforceFields = createAsyncThunk("additional-fields/fetch", async (url: string) => {
  return axios.get(url).then((res) => res.data);
});

export const uploadFiles = createAsyncThunk("invoices/upload", async ({ id, formData }: { id: string; formData: FormData }) => {
  return axios.post(`/invoices/${id}/upload`, formData).then((res) => res.data);
});

export const splitDocument = createAsyncThunk('invoices/splitInvoice', async ({ id, payload }: { id: string, payload: { reProcess: boolean, pageRange: string } }) => {
  return axios.post(`/invoices/${id}/split`, payload);
});

// Then, handle actions in your reducers:
const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    loading: false,
    salesForceFields: [] as SalesForceField[],
    data: [] as Attachment[],
    queryParams: "",
    current: {} as Attachment,
    statusCounts: {} as StatusCounts[],
    totalCount: 0,
    page: 1,
    historical: false,
    originalStatus: "",
    connection: null
  },
  reducers: {
    setInvoiceData(state, action: PayloadAction<FinalizedData>) {
      const documents = [...state.current.finalizedData.documents];
      state.current.finalizedData = action.payload;
      state.current.finalizedData.documents = documents;
    }
  },
  extraReducers: {
    [fetchAllInvoices.fulfilled.type]: (state, action) => {
      state.data = action.payload.response.data;
      state.page = action.payload.response.page;
      state.totalCount = action.payload.response.totalCount;
      state.queryParams = action.payload.queryParams;
      state.historical = action.payload.historical;
      state.loading = false;
    },
    [fetchAllInvoices.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchAllInvoices.rejected.type]: (state, action) => {
      state.loading = false;
    },
    
    [splitDocument.pending.type]: (state, action) => {
      state.loading = true;
    },
    [splitDocument.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchInvoice.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.loading = false;
    },
    [fetchInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [updateInvoice.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.loading = false;
    },
    [updateInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [updateInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },
    
    [checkPotentialDuplicate.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.loading = false;
    },
    [checkPotentialDuplicate.pending.type]: (state, action) => {
      state.loading = true;
    },
    [checkPotentialDuplicate.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [cloneInvoice.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [cloneInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [cloneInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [approveInvoice.fulfilled.type]: (state, action) => {
      state.current.status = "Approved";
      state.loading = false;
    },
    [approveInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [approveInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchInvoiceCounts.fulfilled.type]: (state, action) => {
      state.statusCounts = mapStatusCounts(action.payload);
    },
    [fetchInvoiceCounts.pending.type]: (state, action) => { },
    [fetchInvoiceCounts.rejected.type]: (state, action) => { },

    [fetchSalesForceFields.fulfilled.type]: (state, action) => {
      state.salesForceFields = action.payload;
    },
    [fetchSalesForceFields.pending.type]: (state, action) => { },
    [fetchSalesForceFields.rejected.type]: (state, action) => { },

    [uploadFiles.fulfilled.type]: (state, action) => { },
    [uploadFiles.pending.type]: (state, action) => { },
    [uploadFiles.rejected.type]: (state, action) => { },
  },
});

export const { setInvoiceData } = invoicesSlice.actions;
export default invoicesSlice.reducer;

export interface Attachment {
  filename: string;
  emailId: string;
  id: string;
  finalizedData: FinalizedData;
  lastUpdatedAt: string;
  ocrID: string;
  status: Status;
  url: string;
  fileURL: string;
  presenceUsers: string[];
  docType: string;
  documents: [];

  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
}

export type Status = "New" | "OCR Completed" | "Needs Review" | "Approved" | "Rejected" | "Sent To Salesforce" | "Salesforce Upload Processing" | "Salesforce Upload Complete" | "Skipped" | "Split Processed" | "Salesforce Upload Failure";

export interface OcrData {
  error?: {
    message: string;
  };
  status: string;
  createdDateTime: string;
  lastUpdatedDateTime: string;
  analyzeResult: AnalyzeResult;
}

export interface AnalyzeResult {
  version: string;
  readResults: ReadResult[];
  pageResults: PageResult[];
  documentResults: DocumentResult[];
}

export interface DocumentResult {
  docType: string;
  pageRange: number[];
  fields: { [key: string]: Field | null };
}

export interface Field {
  type: Type;
  valueString?: string;
  text: string;
  boundingBox: number[];
  page: number;
  confidence: number;
  valueDate?: string;
  valueNumber?: number;
}

export enum Type {
  Date = "date",
  Number = "number",
  String = "string",
}

export interface PageResult {
  page: number;
  tables: Table[] | null;
}

export interface Table {
  rows: number;
  columns: number;
  cells: Cell[];
  boundingBox: number[];
}

export interface Cell {
  rowIndex: number;
  columnIndex: number;
  text: string;
  boundingBox: number[];
  columnSpan: number | null;
}

export interface ReadResult {
  page: number;
  angle: number;
  width: number;
  height: number;
  unit: string;
}

interface CascadeOptions {
  [key: string]: string[];
}

export interface SalesForceField {
  id: string;
  relatedField?: string;
  enableIfField: string;
  enableIfField2: string;
  enableIfValue: string;
  enableIfValue2: string;
  enableIfRequired: 0 | 1;
  enableIfRequired2: 0 | 1;
  name: string;
  ocrField: null | string;
  type: string;
  default: null | string;
  dropdownCascadeOnSaved: string;
  dropdownCascade: string;
  dropdownOptions: string[] | CascadeOptions;
  lookupAdditionalFields: null | boolean;
  lookupColumn: null | string;
  lookupField: null | string;
  lookupURL: null | string;
  InvoiceId?: null | string;
  value?: null | string;
  required: 0 | 1;
  readOnly?: 0 | 1;
  readOnlyOnFieldTrue?: string;
}

export interface FinalizedData {
  assetName?: string;
  billingPostalCode?: string;
  currency?: string;
  customerName?: string;
  docType: string;
  originalAttachmentId: string;
  duplicateNumber: number;
  documents: string[];
  emailFrom?: string;
  emailSubject: string;
  invoiceAmount?: number;
  invoiceDate?: string;
  invoiceNumber?: string;
  issuesEmail?: string;
  issuesReason?: string;
  issuesReasonOther?: string;
  lastUpdatedAt: Date;
  notes?: string;
  ocrReceived?: Date;
  orderNumber?: string;
  pages: string[];
  plainFilename?: string;
  poNumber?: string;
  potentialDuplicateInvoiceIds?: string[];
  processedAt: Date;
  processedBy: string;
  processedByName: string;
  rejectedEmail?: string;
  rejectedReason?: string;
  serviceDate: Date;
  serviceTeam?: string;
  status: string;
  statusLastChangeAt?: Date;
  tax?: string;
  vendorName?: string;
  vendorWorkOrderNumber?: string;
  workOrderNumber?: string;
  workOrderNumberWithPrefix: string;
  relatedAttachmentsIds?: string[];
  relatedAttachments?: Attachment[];
}

export enum StatusEnum {
  New = 1,
  NeedsReview,
  CorrectionsNeeded,
  Approved,
  Rejected,
  Submitted,
  OcrFailure,
  SenttoSalesforce,
  PermenantRejection,
}
