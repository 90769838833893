import React from "react";

type Props = {
  src: string;
  envConfig: any;
};

export default function Renderer({ src, envConfig }: Props) {
  const encodeLink = (link: string) =>
    `https://${envConfig.appConfig.storageAccountName}.blob.core.windows.net/dropzonehistory/` +
    encodeURI(link).replaceAll("#", "%23");
  if (!src) return null;
  const encodedSrc = encodeLink(src);

  //http%3A%2F%2Fieee802%2Eorg%3A80%2Fsecmail%2FdocIZSEwEqHFr%2Edoc
  if (src.endsWith(".doc") || src.endsWith(".docx") || src.endsWith(".xls")) {
    return (
      <button onClick={() => {
        fetch(encodedSrc)
        .then( res => res.blob())
        .then(data => {
          const s = src.split('[!]');
          const name = s[s.length-1];
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = name;
          a.click();
        });
      }}>Download</button>
      // <iframe
      //   src={"https://view.officeapps.live.com/op/embed.aspx?src=" + encodedSrc}
      //   width="100%"
      //   height="100%"
      //   title="frame"
      //   //frameborder="0"
      // >
      //   This is an embedded{" "}
      //   <a target="_blank" href="http://office.com" rel="noreferrer">
      //     Microsoft Office
      //   </a>{" "}
      //   document, powered by{" "}
      //   <a target="_blank" href="http://office.com/webapps" rel="noreferrer">
      //     Office Online
      //   </a>
      // </iframe>
    );
    //return <iframe style={{ height: "100%", width: "100%", flex: 1 }} src={`https://docs.google.com/gview?url=${encodedSrc}&embedded=true`}></iframe>;
  }
  return (
    <embed
      src={encodedSrc}
      style={{
        objectFit: "contain",
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    />
  );
}
