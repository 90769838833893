import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { ObjectToQueryParam, StatusCounts, mapStatusCounts } from "../utils/helpers";
import { Email } from "../types";
import { Attachment } from "./invoicesSlice";

export const fetchEmails = createAsyncThunk(
  "emails/fetch",
  async ({ filter }: { filter?: { statusId?: number; sortColumn?: string; sortDirection?: string; }; }, { getState }) => {
    const queryParams = ObjectToQueryParam(filter);

    //@ts-ignore
    const prevQueryParams = getState().emails.queryParams;

    if (prevQueryParams === queryParams) {
      return Promise.reject();
    }
    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (queryParams) {
        newurl += `?${queryParams}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }

    return axios.get(`/emails?${queryParams}`).then((res) => ({ response: res.data, queryParams }));
  }
);

export const fetchEmail = createAsyncThunk("email/fetch", async (id: string) => {
  return axios.get(`/emails/${id}`).then((res) => res.data);
});

export const fetchInvoices = createAsyncThunk("invoices/fetch", async (id: string) => {
  return axios.get(`/invoices?emailId=${id}`).then((res) => res.data);
});

export const fetchEmailCounts = createAsyncThunk("emails/counts", async (_, { getState }) => {
  return axios.get(`/emails-counts`).then((res) => res.data);
});

// Then, handle actions in your reducers:
const emailsSlice = createSlice({
  name: "emails",
  initialState: {
    loading: false,
    data: [] as Email[],
    invoices: [] as Attachment[],
    queryParams: "",
    current: {} as Email,
    statusCounts: [] as StatusCounts[],
    totalCount: 0,
    page: 1,
    historical: false,
    originalStatus: "",
    connection: null,
  },
  reducers: {},
  extraReducers: {
    [fetchEmails.fulfilled.type]: (state, action) => {
      state.data = action.payload.response.data;
      state.page = action.payload.response.page;
      state.totalCount = action.payload.response.totalCount;
      state.queryParams = action.payload.queryParams;
      state.historical = action.payload.historical;
      state.loading = false;
    },
    [fetchEmails.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchEmails.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchEmail.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      //state.originalStatus = state.current.finalizedData.status;
      state.loading = false;
    },
    [fetchEmail.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchEmail.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchInvoices.fulfilled.type]: (state, action) => {
      state.invoices = action.payload.data;
      state.loading = false;
    },
    [fetchInvoices.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchInvoices.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchEmailCounts.fulfilled.type]: (state, action) => {
      state.statusCounts = mapStatusCounts(action.payload);
    },
    [fetchEmailCounts.pending.type]: (state, action) => {},
    [fetchEmailCounts.rejected.type]: (state, action) => { 
      state.loading = false;
    },
  },
});

// export const { addOrRemoveEmailPresence, openEmailConnection } = emailsSlice.actions;
export default emailsSlice.reducer;

export type Status = "New" | "Needs Review" | "Corrections Needed" | "Approved" | "Rejected";

export interface StatusObj {
  id: number;
  label: string;
  showInFilter: boolean;
  key: string;
  color?: string;
}

export enum StatusEnum {
  New = 1,
  NeedsReview,
  CorrectionsNeeded,
  Approved,
  Rejected,
  Submitted,
  OcrFailure,
  SenttoSalesforce,
  PermenantRejection,
}

export const StatusList: StatusObj[] = [
  {
    label: "New",
    id: 1,
    showInFilter: false,
    key: "new",
  },
  {
    label: "Needs Review",
    id: 2,
    showInFilter: true,
    key: "needsReview",
    color: "#0984e3",
  },
  // {
  //   label: "Corrections Needed",
  //   id: 3,
  //   showInFilter: true,
  //   key: "correctionsNeeded",
  //   color: "#e67e22"
  // },
  {
    label: "Approved",
    id: 4,
    showInFilter: true,
    key: "approved",
    color: "#00b894",
  },
  {
    label: "Rejected",
    id: 5,
    showInFilter: true,
    key: "rejected",
    color: "#d63031",
  },
  {
    label: "Sent to Salesforce",
    id: 8,
    showInFilter: true,
    key: "sentToSalesforce",
    color: "#663399",
  },
];
