import React from "react";
import { useMemo, useRef, useState } from "react";

import { Select, SelectProps, Spin } from "antd";
import { debounce } from "lodash";

export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  onScrollToBottom?: (search: string, pageNumber: number) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

let pageNumber: number = 1;
let lastPageReached: boolean = false;

function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number; } = any>({
  fetchOptions,
  onScrollToBottom,
  debounceTimeout = 500,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);

  const fetchRef = useRef(0);
  const searchValueRef = useRef("");

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      searchValueRef.current = value;
      const fetchId = fetchRef.current;

      setOptions([]);
      clearPagination();
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onScroll = (event) => {
    const { target } = event;

    const scrolledToBottom = target.scrollTop + target.offsetHeight === target.scrollHeight;

    if (!lastPageReached && !fetching && scrolledToBottom) {
      setFetching(true);

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      pageNumber = pageNumber + 1;

      onScrollToBottom(searchValueRef.current, pageNumber).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setFetching(false);

        if (!newOptions?.length) {
          lastPageReached = true;
          return;
        }

        setOptions([...options, ...newOptions]);
      });
    }
  };

  const clearPagination = () => {
    pageNumber = 1;
    lastPageReached = false;
  };

  return (
    <Select
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      size="large"
      onSearch={debounceFetcher}
      onPopupScroll={onScroll}
      loading={fetching}
      onBlur={clearPagination}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default DebounceSelect;
