import React, { ChangeEvent, FC, useRef, useState } from "react";
import { Input, InputRef, Tag, Tooltip, message } from "antd";

type TagInputProps = {
  placeholder?: string;
  style?: React.CSSProperties;
  value: string[];
  onSearch: (value: string[]) => void;
};

const TagInput: FC<TagInputProps> = ({ value = [], onSearch, placeholder, ...props }) => {
  const [content, setContent] = useState<string>();
  const inputRef = useRef<InputRef>(null);

  const handleDelete = (tag: string) => {
    const newArr = value.filter((i) => i !== tag);
    onSearch(newArr);
    message.destroy();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handleBlur = () => {
    if (content) {
      if (value.includes(content)) {
        message.open({ type: "warning", content: `[tag: ${content}] already exists` });
        return;
      }
      onSearch([...value, content]);
      setContent("");
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && content) {
      if (value.includes(content)) {
        message.open({ type: "warning", content: `[tag: ${content}] already exists` });
        return;
      }
      onSearch([...value, content]);
      setContent("");
    }
  };

  return (
    <div className={`ant-input`} onClick={() => inputRef.current?.focus()} {...props}>
      {value.map((item) =>
        item.length > 20 ? (
          <Tooltip title={item} key={item}>
            <Tag closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
          </Tooltip>
        ) : (
          <Tag closable key={item} onClose={() => handleDelete(item)}>
            {item}
          </Tag>
        )
      )}
      <Input
        allowClear
        ref={inputRef}
        placeholder={'Hit Enter for search'}
        value={content}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
      />
    </div>
  );
};

export default TagInput;
