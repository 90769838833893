import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

import { IPresenceUser } from "../types";

interface Props {
  id: string;
  presenceUsers: IPresenceUser[];
}

function UserPresenceIcon({ id, presenceUsers }: Props) {
  const idx = presenceUsers.findIndex(p => p.entityId === id);
  return idx > -1 ? (
    <Tooltip placement="topLeft" title={presenceUsers[idx].user}>
      <UserOutlined />
    </Tooltip>
  ) : null;
}

export default UserPresenceIcon;
