import { HubConnection } from "@microsoft/signalr";
import { useEffect } from "react";

import { addOrRemovePresence, openConnection } from "../store/signalRSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { FeatureFlag } from "./featureFlagsProvider";

interface Props {
  id: string;
  envConfig: any;
  features: FeatureFlag;
}

function useSignalR({ id, envConfig, features }: Props) {
  const dispatch = useAppDispatch();
  const userName = useAppSelector((state) => state.user?.userName);
  const connection = useAppSelector<HubConnection>((state) => state.signalRHub.connection);

  useEffect(() => {
    if (!connection) {
      dispatch(openConnection({ url: `${envConfig.appConfig.signalUrl}` }));
      return;
    }
  }, [connection, dispatch, envConfig, features]);

  useEffect(() => {
    if (connection && connection?.state === "Disconnected") {
      connection
        .start()
        .then((_) => {
          connection.on("newMessage", (res) => {
            const [status, user, id] = res.split("[:]");
            dispatch(addOrRemovePresence({ status, user, id }));
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [dispatch, connection, features]);

  useEffect(() => {
    const AddUserToPresence = (userName: string, id: string) => {
      connection
        .invoke(`AddUserToPresence`, userName, id)
        .then()
        .catch((error) => {
          console.error(error);
        });
    };

    const RemoveUserFromPresence = (userName: string, id: string) => {
      connection
        .invoke(`RemoveUserFromPresence`, userName, id)
        .then()
        .catch((error) => {
          console.error(error);
        });
    };

    let interval;
    const isConnected = connection?.state === "Connected";
    if (userName && id && isConnected) {
      AddUserToPresence(userName, id);

      interval = setInterval(() => {
        AddUserToPresence(userName, id);
      }, 30000);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (interval && connection?.state === "Connected") {
        RemoveUserFromPresence(userName, id);
      }
    };
  }, [userName, id, connection]);
}

export default useSignalR;
