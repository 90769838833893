import React, { useContext, useState } from "react";
import { useCallback, useEffect, useRef } from "react";
import { Input, Select, Button, DatePicker, Form, Col, Modal, Spin, Divider, Space, InputRef, Tag, theme, Tooltip, List } from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import moment from "moment";
import dayjs from "dayjs";
import { get } from "lodash";

import {
  fetchAdditionalSalesforceFields,
  fetchInvoice,
  fetchOptions,
  Attachment,
  SalesForceField,
  updateInvoice,
  cloneInvoice,
  checkPotentialDuplicate,
} from "../store/invoicesSlice";
import { FeatureFlags } from "../hooks/featureFlagsProvider";
import { useAppDispatch, useAppSelector } from "../store";
import { explodeFileName, formatDate, parseMomentDate } from "../utils";
import { pushMessage } from "../store/messagesSlice";
import { fetchEmail, fetchInvoices } from "../store/emailsSlice";
import SplitModal from "../components/SplitModal";
import DebounceSelect from "./DebounceSelect";

const TextArea = Input.TextArea;
const { confirm } = Modal;

interface Props {
  onDuplicateError: (data, message) => void;
  invoice: Attachment;
  emailId: string;
  disabled: boolean;
  isInvoice: boolean;
}

function InvoiceFields({ onDuplicateError, invoice, emailId, disabled, isInvoice }: Props) {
  const [filter, setFilter] = useState("");
  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();
  const fields = useAppSelector((state) => state.invoices.salesForceFields);
  const [data, setData] = useState(fields);
  const [options, setOptions] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const invalidFields = useRef(new Set());
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [isUnknown, setIsUnknown] = useState(false);
  const [isSuretyModalOpen, setIsSuretyModalOpen] = useState({ message: "", isOpen: false });
  const [isDuplicatedWarningModal, setIsDuplicatedWarningModal] = useState({ potentialDuplicateInvoiceIds: [], isOpen: false });
  const inputRef = useRef<InputRef>(null);
  const [newOptionName, setNewOptionName] = useState("");
  const { features } = useContext(FeatureFlags);
  const controlSize = features?.pageLayout.controlsSize ?? "large";
  const highlightEmptyFields = features?.highlightEmptyFields ?? false;
  const [isStatusChangeAllowSubmit, setIsStatusChangeAllowSubmit] = useState(false);
  const approveAllFlag = useRef(false);
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const editInputRef = useRef<InputRef>(null);
  const getValueFromDataArray = (key): any => {
    return data.find((el) => el.id === key)?.value;
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const getValueFromFinalizedData = useCallback(
    (key: string, type?: string) => {
      if (!invoice || !invoice.finalizedData) return null;

      const val = invoice.finalizedData[key];
      if (type === "date" && val) {
        try {
          const dateVal = new Date(val).toISOString().slice(0, 10);
          return dateVal;
        } catch (e) {
          console.error(e);
        }
      }

      if (type === "tag" && val) {
        try {
          const tagsVal = setTags(val.split(","));
          return tagsVal;
        } catch (e) {
          console.error(e);
        }
      }
      return val?.toString();
    },
    [invoice]
  );
  
  const getArrayValueFromFinalizedData = useCallback(
    (key: string, type?: string) => {
      if (!invoice || !invoice.finalizedData) return null;

      const val = invoice.finalizedData[key];
      return val;
    },
    [invoice]
  );

  const onSave = () => {
    const finalizedData = {};

    data.forEach((e) => {
      if (e?.readOnly) return;
      if (e?.id === "salesForceWorkOrder" && e?.value === e?.default) return;
      finalizedData[e.id] = (e?.value !== null) ? e?.value : "";
      if (e?.lookupURL && e?.[`${e.id}Id`]) finalizedData[`${e.id}Id`] = e?.[`${e.id}Id`];
    });

    if (finalizedData["status"] === "Approved") {

      if (features.isCheckPotentialDuplicated) {
        //@ts-ignore
        dispatch(checkPotentialDuplicate({ id: invoice.id, finalizedData })).then(res => {
          if (res.payload.length > 0) {
            setIsDuplicatedWarningModal({ potentialDuplicateInvoiceIds: res.payload, isOpen: true });
          } else {
            setIsSuretyModalOpen({
              message: isInvoice
                ? "Are you ready to submit?"
                : "Are you ready to submit? This file will be sent to Salesforce and fields will become un-editable upon submission.",
              isOpen: true,
            });
          }
        });
      } else {
        setIsSuretyModalOpen({
          message: isInvoice
            ? "Are you ready to submit?"
            : "Are you ready to submit? This file will be sent to Salesforce and fields will become un-editable upon submission.",
          isOpen: true,
        });
      }
    } else if (finalizedData["status"] === "Rejected" || finalizedData["status"] === "Skipped") {
      if (finalizedData["rejectedEmail"] !== "")
      {
        setIsSuretyModalOpen({
          message: isInvoice
            ? "You are about to reject this document. The fields will become un-editable upon submission. This will send an email message to the following addresses: " + finalizedData["rejectedEmail"]
            : "You are about to reject this document. This file will be NOT sent to Salesforce and fields will become un-editable upon submission. This will send an email message to the following addresses: " + finalizedData["rejectedEmail"],
          isOpen: true,
        });
      }
      else
      {
        setIsSuretyModalOpen({
          message: isInvoice
            ? "You are about to reject or skip this document. The fields will become un-editable upon submission."
            : "You are about to reject or skip this document. This file will be NOT sent to Salesforce and fields will become un-editable upon submission.",
          isOpen: true,
        });
      }
    }else if (finalizedData["status"] === "Issues") {
      if (finalizedData["issuesEmail"] !== "")
      {
        setIsSuretyModalOpen({
          message: isInvoice
            ? "You are about to mark this document as issues. This will send an email message to the following addresses: " + finalizedData["issuesEmail"]
            : "You are about to mark this document as issues. This file will be NOT sent to Salesforce. This will send an email message to the following addresses: " + finalizedData["issuesEmail"],
          isOpen: true,
        });
      }
      else
      {
        setIsSuretyModalOpen({
          message: isInvoice
            ? "You are about to reject or skip this document. The fields will become un-editable upon submission."
            : "You are about to reject or skip this document. This file will be NOT sent to Salesforce and fields will become un-editable upon submission.",
          isOpen: true,
        });
      }
    }  
    else if (finalizedData["status"] === "Completed" && isInvoice) {
      setIsSuretyModalOpen({
        message: "Are you ready to submit? The fields will become un-editable upon submission.",
        isOpen: true,
      });
    } else submit();
  };

  const submit = () => {
    const finalizedData = {};

    data.forEach((e) => {
      if (e?.readOnly) return;
      if (e?.id === "salesForceWorkOrder" && e?.value === e?.default) return;
      finalizedData[e.id] = (e?.value !== null) ? e?.value : "";
      if (e?.lookupURL && e?.[`${e.id}Id`]) finalizedData[`${e.id}Id`] = e?.[`${e.id}Id`];
    });

    if (finalizedData["status"] === "Approved" || finalizedData["status"] === "Rejected") {
      setShowErrors(true);

      if (invalidFields.current.size && !isStatusChangeAllowSubmit)
        return dispatch(
          pushMessage({
            message: "Form Is Not Valid",
            description: "Missing: " + Array.from(invalidFields.current).toString(),
            type: "error",
          })
        );
    }

    //@ts-ignore
    dispatch(updateInvoice({ id: invoice.id, finalizedData, ignore: false, approveAllFlag: approveAllFlag.current })).then((res) => {
      if (isInvoice) {
        dispatch(fetchInvoice(invoice.id));
      } else {
        dispatch(fetchInvoices(emailId));
        dispatch(fetchEmail(emailId));
      }
      if (res.meta.requestStatus === "rejected") {
        onDuplicateError(finalizedData, res["error"].message);
      }
      approveAllFlag.current = false;
    });
  };

  const checkIfSubmit = useCallback(() => {
    if (!invoice || !invoice?.finalizedData) return null;
    let isResubmit = features.allowResubmitReasonValue.includes(invoice.finalizedData[features.allowResubmitReasonField]);

    return (
      isResubmit ||
      ["Approved", "Salesforce Upload Failure", "Salesforce Validation Complete", "Needs Review", "Issues"].includes(invoice.finalizedData?.status)
    );
  }, [invoice, features]);

  useEffect(() => {
    const fromFinalizedData = (el: SalesForceField) => getValueFromFinalizedData(el.id, el.type) || el.default;
    
    const fieldInitialValue = (el: SalesForceField) => {
      return el.readOnlyOnFieldTrue ? 
        getValueFromFinalizedData(el.readOnlyOnFieldTrue, "label")?.toString() === "true" ? "" : fromFinalizedData(el)
        :
        fromFinalizedData(el);
    };

    setData(fields.map((el) => ({ ...el, value: fieldInitialValue(el) })));
    setSubmitAllowed(checkIfSubmit());
  }, [fields, getValueFromFinalizedData, checkIfSubmit]);

  const checkIfDuplicate = useCallback(() => {
    if (!invoice || !invoice?.finalizedData) return false;
    const isEnabled = features.isCloneInvoiceEnabled;
    const duplicateStatus = features.cloneInvoiceStatus;

    return isEnabled && duplicateStatus.includes(invoice?.finalizedData?.status)
      && invoice?.finalizedData?.docType === 'Invoice';
  }, [invoice, features]);

  const duplicate = () => {
    confirm({
      title: "Are you sure?",
      okText: "Yes",
      cancelText: "No",
      content: "Are you sure you want to duplicate this Invoice?",
      icon: <QuestionCircleOutlined />,
      onOk() {
        dispatch(cloneInvoice({ id: invoice.id })).then(() => {
          if (isInvoice) {
            dispatch(fetchInvoice(invoice.id));
          } else {
            dispatch(fetchInvoices(emailId));
            dispatch(fetchEmail(emailId));
          }
        });
      },
    });
  };

  const checkIfHasClones = ()=> {
    return getArrayValueFromFinalizedData("cloneInvoiceIds")?.length > 0;
  };

  const handleChange = async (key: string, value: string | boolean, fieldName: string, id?: string) => {
    const tempData = [...data];
    let index = tempData.findIndex((el) => el.id === key);
    tempData[index] = { ...tempData[index], value } as SalesForceField;
    if (key === "status") {
      setIsStatusChangeAllowSubmit(["Rejected", "Issues", "Skipped"].includes(value as string));
    }
    if (key === "vendor") {
      const i = data.findIndex((d) => d.id === "vendorId");
      if (i > 0) data[i].value = id;
    }
    if (key === "asset") {
      let i = data.findIndex((d) => d.id === "assetId");
      if (i > 0) data[i].value = id;
      i = data.findIndex(d => d.id === 'tax');
      if (i > 0) data[i].value = null;
    }
    if (key === "issuesEmail") {
      const i = data.findIndex((d) => d.id === "issuesEmail");
      if (i > 0) data[i].value = id;
    }
    if (id && tempData[index].lookupURL && tempData[index].lookupAdditionalFields) {
      tempData[index] = { ...tempData[index], [`${tempData[index].id}Id`]: id };
      await dispatch(fetchAdditionalSalesforceFields(`${tempData[index].lookupURL}/${id}`)).then(({ payload }) => {
        if (!payload) return;
        data.forEach((d) => {
          if (d.lookupColumn) {
            const y = get(payload, d.lookupColumn);
            if (y !== null) d.value = y;
          }
        });
        setData(data);
      });
    }
    if (fieldName === "Document Type") {
      setIsUnknown(value === "Unknown");
    }
    if (typeof value === "string" && value?.length === 0) {
      clearMappingField(key);
    }
    if (key !== "docType") clearRelatedFields(key);
    setData([...tempData] as SalesForceField[]);
  };

  const getTotal = () => {
    const fields = [];
    for (let field of features.invoiceTotalValue.fields) fields.push(parseFloat(data.filter((f) => f.id === field)[0]?.value) || 0.0);

    let tax = 0;
    for (let taxField of features.invoiceTotalValue.taxFields) {
      const tf = data.find((d) => d.id === taxField);
      const reqField = data.find((e) => e.id === tf.enableIfField);
      if (tf.enableIfValue.includes(reqField?.value)) tax += parseFloat(data.find((f) => f.id === taxField)?.value) || 0.0; // eslint-disable-line -- tax variable is used on eval expression below
    }

    let total = eval(features.invoiceTotalValue.expression); // eslint-disable-line -- eval is used to calculate total
    total = parseFloat(total).toFixed(2);
    data.filter((d) => d.id === "invoiceTotalCalculated").forEach((f) => (f.value = total));
  };

  const clearRelatedFields = (key: string) => {
    const tempData = [...data];

    tempData.forEach((el) => {
      if (el.enableIfField === key || el.enableIfField2 === key) {
        el.value = "";
      }
    });

    setData([...tempData] as SalesForceField[]);
  };

  const clearMappingField = (key: string) => {
    const tempData = [...data];

    const mappingField = tempData.find((f) => f.id === key);

    if (mappingField && mappingField.ocrField) {
      const fieldIdx = data.findIndex((f) => f.lookupField === mappingField.ocrField);
      const field = data.find((f) => f.lookupField === mappingField.ocrField);
      if (fieldIdx > -1 && field) {
        field.value = "";

        tempData.splice(fieldIdx, 1, field);
      }
    }

    if (mappingField && mappingField.lookupURL && mappingField.lookupAdditionalFields) {
      tempData.forEach((d) => {
        if (d.lookupColumn) {
          d.value = "";
        }
      });
    }

    setData([...tempData] as SalesForceField[]);
  };

  const getValidatedStatus = (el: SalesForceField) => {
    //check if field is required ant not valid
    const isInvalid = !el?.value && !el.default;

    if (isInvalid && checkIfRequired(el)) {
      invalidFields.current.add(el.name);

      return showErrors ? "error" : "success";
    }
    invalidFields.current.delete(el.name);

    return "success";
  };

  const checkIfRelatedFieldEqual = (el: SalesForceField) =>
    el.enableIfValue.includes(getValueFromDataArray(el.enableIfField)) &&
    (el.enableIfValue2 ? el.enableIfValue2.includes(getValueFromDataArray(el.enableIfField2)) : true);

  const checkIfRequired = (el: SalesForceField): boolean => {
    if ((el.enableIfField && el.enableIfRequired) || (el.enableIfField2 && el.enableIfRequired2)) {
      if (checkIfRelatedFieldEqual(el)) return true;

      return false;
    }
    if (el.required) {
      return true;
    }

    return false;
  };

  const addLabelMargin = (fieldType: string) => (controlSize === "large" && fieldType === "label" ? "label-field-margin" : "");

  const renderLabel = (el: SalesForceField) => {
    const isRequired = checkIfRequired(el);
    return isRequired ? `${el.name} *` : el.name;
  };

  const disabledDate = (current) => {
    // Can not select future days
    return current && current > moment().endOf("day");
  };

  const handleOptions = (lookupField: string, lookupUrl: string): void => {
    setOptions([]);
    const field = data.find((f) => f.ocrField === lookupField);
    if (field.value?.length > 0) {
      setFetching(true);

      fetchOptions(lookupUrl.replace(`{{${lookupField}}}`, field.value))
        .then((values) => {
          if (values) {
            setOptions(values.map((v) => ({ label: v, value: v })));
          }
          setFetching(false);
        })
        .catch((err: any) => {
          console.error(err);
          setFetching(false);
        });
    }
  };

  const fetchDropdownOptions = async (id: string, criteria: string, lookupUrl: string, pageNumber: number = 1): Promise<any[]> => {
    let options = [];

    if (criteria.length >= 3) {
      const values = await fetchOptions(`${lookupUrl}?search=${criteria}&page=${pageNumber}`).catch((err: any) => {
        console.error(err);
        return options;
      });

      if (values)
        options = values.map((v) => ({
          key: v.id,
          label: getDropdownLabel(id, v),
          value: getDropdownLabel(id, v),
        }));
    }
    return options;
  };

  const getDropdownLabel = (id: string, v: any) => {
    switch (id) {
      case "asset":
        return v.serialNumber
          ? `${v.name} #${v.serialNumber} (${v.shippingStreet}, ${v.shippingCity}, ${v.shippingState} ${v.shippingCountry})`
          : `${v.name} (${v.shippingStreet}, ${v.shippingCity}, ${v.shippingState} ${v.shippingCountry})`;
      case "vendor":
        return v.vendor_ID__c ? `${v.name} (${v.vendor_ID__c})` : v.name;
      default:
        return v.name;
    }
  };

  const isAcceptedDocType = () => {
    const status = getValueFromDataArray("status");
    return status !== "Needs Review" && status !== "Issues" && status !== "Rejected" && features?.isSubmitRestrictedByStatus
      ? !(features?.disabledSubmitStatuses).includes(getValueFromDataArray("docType"))
      : true;
  };

  const hasInvalidFields = () => {
    const status = getValueFromDataArray("status");
    return status === "Needs Review" || status === "Issues" || status === "Rejected" ? false : invalidFields.current.size > 0;
  };

  const getDropdownOptionLabel = (el: SalesForceField, e: string) => {
    switch (el.id) {
      case "status":
        return features?.statusRename.find((sr: any) => sr.status === e)?.displayAs ?? e;
      case "docType":
        return features?.docTypeRename.find((sr: any) => sr.docType === e)?.displayAs ?? e;
      default:
        return e;
    }
  };

  const renderField = (el: SalesForceField, disabled: boolean, isRequired: boolean) => {
    const status = highlightEmptyFields && isRequired && !disabled && !el.value ? "error" : "";

    if (el.type === "date") {
      return (
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: "100%" }}
          size={controlSize}
          status={status}
          onChange={(_, value) => handleChange(el.id, value, el.name)}
          value={el.value ? dayjs(parseMomentDate(el.value)) : null}
          disabled={disabled}
          disabledDate={disabledDate}
        />
      );
    }

    if (el.type === "dropdown") {
      if (!el.lookupURL) {
        let options = Array.isArray(el.dropdownOptions) ? el.dropdownOptions : [];

        if (el.dropdownCascadeOnSaved) {
          let status = getValueFromFinalizedData("status");
          options = el.dropdownOptions[status] || [];
        }

        if (el.dropdownCascade) {
          //getting selected cascade from dataArray or finalized data
          const selectedCascade = getValueFromDataArray(el.dropdownCascade) || getValueFromFinalizedData(el.dropdownCascade);

          if (!selectedCascade) {
            options = [];
            if (el.value) {
              handleChange(el.id, "", el.name);
            }
          } else {
            options = el.dropdownOptions[selectedCascade] || [];

            if (el.value && !options.includes(el.value)) {
              handleChange(el.id, "", el.name);
            } else if (options.length === 1 && !el.value) {
              handleChange(el.id, options[0], el.name);
            }
          }
        }

        let displayValue = features?.statusRename.find((sr: any) => sr.status === el.value)?.displayAs ?? el.value;
        displayValue = features?.docTypeRename.find((sr: any) => sr.docType === el.value)?.displayAs ?? el.value;

        return (
          <Select
            showSearch
            size={controlSize}
            options={options.map((e: string) => ({ key: e, label: getDropdownOptionLabel(el, e), value: e }))}
            status={status}
            style={{ width: "100%" }}
            onSelect={(value) => handleChange(el.id, value, el.name)}
            value={displayValue}
            disabled={disabled}
          ></Select>
        );
      } else if (el.lookupURL) {
        return (
          <DebounceSelect
            key={el.id}
            fetchOptions={(criteria: string) => fetchDropdownOptions(el.id, criteria, el.lookupURL)}
            onScrollToBottom={(criteria: string, pageNumber: number) => fetchDropdownOptions(el.id, criteria, el.lookupURL, pageNumber)}
            onSelect={(value) => {
              handleChange(el.id, value.value, el.name, value.key);
            }}
            onClear={() => handleChange(el.id, "", el.name)}
            style={{ width: "100%" }}
            status={status}
            disabled={disabled}
            size={controlSize}
            value={el.value}
          />
        );
      }
    }

    if (el.type === "dropdownSearch") {
      return (
        <Select
          allowClear
          size={controlSize}
          style={{ width: "100W%" }}
          status={status}
          value={el.value}
          onDropdownVisibleChange={() => handleOptions(el.lookupField, el.lookupURL)}
          onSelect={(value) => handleChange(el.id, value, el.name)}
          onClear={() => handleChange(el.id, "", el.name)}
          options={options}
          notFoundContent={fetching ? <Spin size="small" /> : []}
          disabled={disabled}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input placeholder="Order #" ref={inputRef} value={newOptionName} onChange={onNameChange} />
                <Button size={controlSize} type="text" icon={<PlusOutlined />} onClick={addItem}>
                  Add
                </Button>
              </Space>
            </>
          )}
        ></Select>
      );
    }

    if (el.type === "textarea") {
      return (
        <TextArea
          className="text-area"
          disabled={disabled}
          autoSize={{ minRows: 2 }}
          size={controlSize}
          status={status}
          onChange={(e) => handleChange(el.id, e.target.value, el.name)}
          value={el.value}
        />
      );
    }

    if (el.type === "label") {
      if (el.id === "invoiceTotalCalculated") {
        if (features.invoiceTotalValue) getTotal();
        return (
          <Input
            disabled={disabled}
            size={controlSize}
            status={status}
            readOnly
            onChange={(e) => handleChange(el.id, e.target.value, el.name)}
            type={el.type}
            value={el.value}
          />
        );
      } else return <Input size={controlSize} disabled value={el.value}></Input>;
    }

    if (el.type === "search") {
      return (
        <Search
          disabled={disabled}
          size={controlSize}
          status={status}
          readOnly={!!el.readOnly}
          onChange={(e) => handleChange(el.id, e.target.value, el.name)}
          type={el.type}
          value={el.value}
        />
      );
    }

    if (el.type === "tag") {
      const handleClose = (removedTag: string) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
        handleChange(el.id, newTags.length > 0 ? newTags.toString() : "", el.name);
      };

      const showInput = () => {
        setInputVisible(true);
      };

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
      };

      const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
          setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue("");
        handleChange(el.id, tags.length > 0 ? tags.toString() + "," + inputValue : inputValue, el.name);
      };

      const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
      };

      const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue("");
        handleChange(el.id, newTags.length > 0 ? newTags.toString() : editInputValue, el.name);
      };

      const tagInputStyle: React.CSSProperties = {
        width: 64,
        height: 22,
        marginInlineEnd: 8,
        verticalAlign: "top",
      };

      const tagPlusStyle: React.CSSProperties = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: "dashed",
      };

      return (
        <Space size={[0, 8]} wrap>
          <Space size={[0, 8]} wrap>
            {tags.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={editInputRef}
                    key={tag}
                    size={controlSize}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                );
              }
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag key={tag} closable style={{ userSelect: "none" }} onClose={() => handleClose(tag)}>
                  <span
                    onDoubleClick={(e) => {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
            {inputVisible ? (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                style={tagInputStyle}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            ) : (
              <Tag style={tagPlusStyle} onClick={showInput}>
                <PlusOutlined /> New Tag
              </Tag>
            )}
          </Space>
        </Space>
      );
    }

    if (el.type === "positive") {
      if (el.value?.includes(',')) {
        el.value = el.value.replace(/,/g, '');
      }

      return (
        <Input
          disabled={disabled}
          size={controlSize}
          status={status}
          min="0"
          onChange={(e) => handleChange(el.id, e.target.value, el.name)}
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            const value = +target.value;
            target.value = (!!value && Math.abs(value) >= 0) ? Math.abs(value).toString() : '';
          }}
          type="number"
          value={el.value}
        />
      );
    }

    const readOnlyOnField = el.readOnlyOnFieldTrue ? getValueFromDataArray(el.readOnlyOnFieldTrue)?.toString() === "true" : false;

    return (
      <Input
        disabled={disabled || readOnlyOnField}
        size={controlSize}
        status={status}
        readOnly={!!el.readOnly}
        onChange={(e) => handleChange(el.id, e.target.value, el.name)}
        type={el.type}
        value={el.value}
      />
    );
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOptionName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    if (newOptionName?.length > 0) {
      setOptions([...options, { label: newOptionName, value: newOptionName }]);
      setNewOptionName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const renderFormItem = (el: SalesForceField) => {
    if (el.enableIfField && !checkIfRequired(el)) {
      //remove from invalid fields if this field is not visible anymore
      invalidFields.current.delete(el.name);
      if (!checkIfRelatedFieldEqual(el)) {
        //clear hidden field value to resolve staled data cacscade bug (when selecting Other option)
        return null;
      }
      // return null;
    }
    const isRequired = checkIfRequired(el);
    return (
      <Form.Item required={isRequired} key={el.id} validateStatus={getValidatedStatus(el)}>
        <div className="field">
          <span className={`field-label ${addLabelMargin(el.type)}`}>{renderLabel(el)}</span>
          <span className={`field-value ${addLabelMargin(el.type)}`}>{renderField(el, !submitAllowed || disabled, isRequired)}</span>
        </div>
      </Form.Item>
    );
  };

  if (!invoice) return null;

  const handleOk = () => {
    setIsSuretyModalOpen({ message: "", isOpen: false });
    setIsDuplicatedWarningModal({ potentialDuplicateInvoiceIds: [], isOpen: false });
    submit();
  };

  let processedAt = getValueFromFinalizedData("processedAt");
  if (processedAt) {
    processedAt = formatDate(processedAt);
  }
  const processedBy = getValueFromFinalizedData("processedByName");

  return (
    <div className="invoice-fields">
      <Input.Search
        onChange={(e) => setFilter(e.target.value)}
        allowClear
        placeholder="Search Field"
        size={controlSize}
        loading={false}
        style={isInvoice ? { width: "50%" } : { width: "100%" }}
      />
      <div className="field-container">
        {data
          .filter(
            (el) =>
              el.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
              el.ocrField?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          )
          .map((el, index) => renderFormItem(el))}
        {processedAt && (
          <div className="field mt-10px">
            <span className={`field-label ${addLabelMargin("label")}`}>Processed At:</span>
            <span className={`field-value ${addLabelMargin("label")}`}>{processedAt}</span>
          </div>
        )}
        {processedBy && (
          <div className="field">
            <span className={`field-label ${addLabelMargin("label")}`}>Processed By:</span>
            <span className={`field-value ${addLabelMargin("label")}`}>{processedBy}</span>
          </div>
        )}
        <div className="field">
          <Col>
            {submitAllowed && !isUnknown && (
              <Button
                size={controlSize}
                onClick={onSave}
                disabled={!isStatusChangeAllowSubmit && (disabled || !isAcceptedDocType() || hasInvalidFields())}
                style={{ width: 100, alignSelf: "center", margin: 10 }}
              >
                Submit
              </Button>
            )}
            {checkIfDuplicate() && (
              <Button type="primary" size={controlSize} style={{ width: 100, margin: 10 }} onClick={duplicate} disabled={disabled}>
                Duplicate
              </Button>
            )}
            {submitAllowed && features.isCloneInvoiceEnabled && checkIfHasClones() && (
              <Button
                type="primary"
                size={controlSize}
                onClick={() => {
                  approveAllFlag.current = true;
                  onSave();
                }}
                disabled={disabled}
              >
                Approve All
              </Button>
            )}
          </Col>

          <Col>
            <SplitModal invoice={invoice}></SplitModal>
          </Col>
        </div>
      </div>
      <Modal
        title="Are you sure?"
        open={isSuretyModalOpen.isOpen}
        onOk={handleOk}
        onCancel={() => setIsSuretyModalOpen({ message: "", isOpen: false })}
      >
        <p>{isSuretyModalOpen.message}</p>
      </Modal>
      <Modal
        title="This is a potential duplicate, do you want to proceed?"
        open={isDuplicatedWarningModal.isOpen}
        onOk={handleOk}
        onCancel={() => setIsDuplicatedWarningModal({ potentialDuplicateInvoiceIds: [], isOpen: false })}
      >
        <List
          size="small"
          header={<div>Potential Duplicates:</div>}
          dataSource={isDuplicatedWarningModal.potentialDuplicateInvoiceIds}
          style={!isInvoice ? { marginLeft: "42px" } : {}}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                window.open(
                  `/invoice/${features.IsDetailsMultiFile ? item.split("[!]")[1] : item.split("[!]")[0]}?isInvoice=${!features.IsDetailsMultiFile}`,
                  "_blank"
                );
              }}
            >
              {explodeFileName(item).filename}
            </List.Item>
          )}
        ></List>
      </Modal>
    </div>
  );
}

export default InvoiceFields;
